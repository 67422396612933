import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from './operations'
const defaultOptions = {} as const
export const ContractFragmentFragmentDoc = gql`
  fragment ContractFragment on Contract {
    id
    employeeId
    endDate
    hospitalLeave
    masterContractId
    payrollPeriod
    probationPeriod
    probationStatus
    publicHolidaySchedule
    sickLeave
    startDate
    annualLeave
    contractFiles {
      id
      filename
      downloadUrl
      createdAt
    }
    status
    masterContract {
      currency
      country
      contractType
    }
    employee {
      firstName
      lastName
      fullNameOnId
      avatarUrl
    }
    contractDays {
      id
      contractId
      day
      startTime
      endTime
      __typename
    }
    contractDetails {
      id
      contractId
      startDate
      endDate
      version
      basicSalary
      allowance
      entitledToCommission
      nonTaxableAllowance
      employmentLevel
      __typename
    }
    contractShiftSchedules {
      id
      contractId
      date
      startTime
      endTime
      __typename
    }
    __typename
  }
`
export const EmployeeFragmentFragmentDoc = gql`
  fragment EmployeeFragment on Employee {
    id
    firstName
    lastName
    fullNameOnId
    address
    taxType
    avatarUrl
    bankName
    bankAccountNumber
    contracts {
      __typename
      startDate
      masterContract {
        contractType
        currency
      }
      contractDays {
        startTime
        day
        endTime
      }
      contractShiftSchedules {
        startTime
        endTime
      }
    }
    dateOfBirth
    department
    email
    emergencyContactName
    emergencyContactNumber
    emergencyContactRelationship
    idCardNumber
    jobTitle
    managerId
    manager {
      name
    }
    maritalStatus
    nationality
    numberOfChildren
    phoneNumber
    religion
    status
    lastWorkingDay
    workEmail
    companyName
    leaveAccount {
      annualLeaveAccrued
      annualLeaveTaken
      annualLeaveBalance
      sickLeaveAccrued
      sickLeaveTaken
      sickLeaveBalance
      hospitalLeaveAccrued
      hospitalLeaveTaken
      hospitalLeaveBalance
      wfhAccrued
      wfhTaken
      wfhBalance
      noPayLeaveTaken
      annualLeaveRollover
    }
  }
`
export const PayrollAdjustmentFragmentFragmentDoc = gql`
  fragment PayrollAdjustmentFragment on PayrollAdjustment {
    id
    amount
    currency
    accountableForTax
    adjustmentType
    description
    employeeId
    startDate
    endDate
    payrollId
  }
`
export const LeaveApplicationFragmentFragmentDoc = gql`
  fragment LeaveApplicationFragment on LeaveApplication {
    id
    daysApplied
    employeeId
    createdAt
    updatedAt
    startDate
    endDate
    leaveType
    reason
    status
    leaveApplicationFiles {
      id
      createdAt
      downloadUrl
      filename
    }
    employee {
      manager {
        name
      }
      firstName
      lastName
      department
      leaveAccount {
        annualLeaveAccrued
        annualLeaveTaken
        annualLeaveBalance
        sickLeaveAccrued
        sickLeaveTaken
        sickLeaveBalance
        hospitalLeaveAccrued
        hospitalLeaveTaken
        hospitalLeaveBalance
        wfhAccrued
        wfhTaken
        wfhBalance
        noPayLeaveTaken
      }
    }
  }
`
export const PayrollFragmentFragmentDoc = gql`
  fragment PayrollFragment on Payroll {
    id
    amount
    contract {
      masterContract {
        contractType
        currency
        country
      }
    }
    employee {
      id
      fullNameOnId
      department
      nationality
      manager {
        name
      }
    }
    fromDate
    toDate
    paidAmount
    paymentStatus
    payrollPayments {
      date
      transferMethod
    }
    payslips {
      id
      payrollId
      allowance
      basicPay
      bonus
      deduction
      id
      nett
      gross
      overtime
      updatedAt
      payslipItems {
        accountableForTax
        amount
        contractId
        description
        id
        itemType
        payrollCyclePeriodId
        payslipId
        status
      }
      status
    }
    status
  }
`
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: resetPasswordInput!) {
    resetPassword(input: $input) {
      user {
        ... on SuperAdminUser {
          id
          firstName
          lastName
          type
        }
        ... on AdminUser {
          id
          employeeId
          firstName
          lastName
          type
        }
        ... on ManagerUser {
          id
          employeeId
          firstName
          lastName
          type
        }
        ... on StaffUser {
          id
          employeeId
          firstName
          lastName
          type
        }
      }
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationResult =
  Apollo.MutationResult<Types.ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($input: sendResetPasswordInput!) {
    sendResetPassword(input: $input) {
      result {
        ... on NotFoundProblem {
          entity
          message
        }
      }
    }
  }
`
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  Types.SendResetPasswordMutation,
  Types.SendResetPasswordMutationVariables
>

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SendResetPasswordMutation,
    Types.SendResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.SendResetPasswordMutation,
    Types.SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options)
}
export type SendResetPasswordMutationHookResult = ReturnType<
  typeof useSendResetPasswordMutation
>
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<Types.SendResetPasswordMutation>
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.SendResetPasswordMutation,
  Types.SendResetPasswordMutationVariables
>
export const SignInDocument = gql`
  mutation SignIn($input: signInInput!) {
    signIn(input: $input) {
      result {
        ... on SignInOk {
          jwt
          jwtExpiry
          refreshToken
          viewer {
            currentUser {
              ... on SuperAdminUser {
                id
                firstName
                lastName
                type
              }
              ... on AdminUser {
                id
                employeeId
                firstName
                lastName
                type
              }
              ... on ManagerUser {
                id
                employeeId
                firstName
                lastName
                type
              }
              ... on StaffUser {
                id
                employeeId
                firstName
                lastName
                type
              }
            }
          }
        }
        ... on ValidationProblem {
          errors {
            field
            messages
          }
          entity
          message
        }
      }
    }
  }
`
export type SignInMutationFn = Apollo.MutationFunction<
  Types.SignInMutation,
  Types.SignInMutationVariables
>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SignInMutation,
    Types.SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.SignInMutation,
    Types.SignInMutationVariables
  >(SignInDocument, options)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationResult = Apollo.MutationResult<Types.SignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  Types.SignInMutation,
  Types.SignInMutationVariables
>
export const ContractByFiltersDocument = gql`
  query ContractByFilters($filters: ContractsByFilter) {
    contractByFilters(filters: $filters) {
      count
      contracts {
        ...ContractFragment
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`

/**
 * __useContractByFiltersQuery__
 *
 * To run a query within a React component, call `useContractByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContractByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ContractByFiltersQuery,
    Types.ContractByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ContractByFiltersQuery,
    Types.ContractByFiltersQueryVariables
  >(ContractByFiltersDocument, options)
}
export function useContractByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ContractByFiltersQuery,
    Types.ContractByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ContractByFiltersQuery,
    Types.ContractByFiltersQueryVariables
  >(ContractByFiltersDocument, options)
}
export type ContractByFiltersQueryHookResult = ReturnType<
  typeof useContractByFiltersQuery
>
export type ContractByFiltersLazyQueryHookResult = ReturnType<
  typeof useContractByFiltersLazyQuery
>
export type ContractByFiltersQueryResult = Apollo.QueryResult<
  Types.ContractByFiltersQuery,
  Types.ContractByFiltersQueryVariables
>
export const ContractByIdDocument = gql`
  query ContractById($id: ID!) {
    contractById(id: $id) {
      ...ContractFragment
    }
  }
  ${ContractFragmentFragmentDoc}
`

/**
 * __useContractByIdQuery__
 *
 * To run a query within a React component, call `useContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ContractByIdQuery,
    Types.ContractByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ContractByIdQuery,
    Types.ContractByIdQueryVariables
  >(ContractByIdDocument, options)
}
export function useContractByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ContractByIdQuery,
    Types.ContractByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ContractByIdQuery,
    Types.ContractByIdQueryVariables
  >(ContractByIdDocument, options)
}
export type ContractByIdQueryHookResult = ReturnType<
  typeof useContractByIdQuery
>
export type ContractByIdLazyQueryHookResult = ReturnType<
  typeof useContractByIdLazyQuery
>
export type ContractByIdQueryResult = Apollo.QueryResult<
  Types.ContractByIdQuery,
  Types.ContractByIdQueryVariables
>
export const CreateContractDocument = gql`
  mutation CreateContract($input: createContractInput!) {
    createContract(input: $input) {
      contract {
        ...ContractFragment
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`
export type CreateContractMutationFn = Apollo.MutationFunction<
  Types.CreateContractMutation,
  Types.CreateContractMutationVariables
>

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateContractMutation,
    Types.CreateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateContractMutation,
    Types.CreateContractMutationVariables
  >(CreateContractDocument, options)
}
export type CreateContractMutationHookResult = ReturnType<
  typeof useCreateContractMutation
>
export type CreateContractMutationResult =
  Apollo.MutationResult<Types.CreateContractMutation>
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateContractMutation,
  Types.CreateContractMutationVariables
>
export const CreateEmployeeDocument = gql`
  mutation CreateEmployee($input: createEmployeeInput!) {
    createEmployee(input: $input) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragmentFragmentDoc}
`
export type CreateEmployeeMutationFn = Apollo.MutationFunction<
  Types.CreateEmployeeMutation,
  Types.CreateEmployeeMutationVariables
>

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateEmployeeMutation,
    Types.CreateEmployeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateEmployeeMutation,
    Types.CreateEmployeeMutationVariables
  >(CreateEmployeeDocument, options)
}
export type CreateEmployeeMutationHookResult = ReturnType<
  typeof useCreateEmployeeMutation
>
export type CreateEmployeeMutationResult =
  Apollo.MutationResult<Types.CreateEmployeeMutation>
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateEmployeeMutation,
  Types.CreateEmployeeMutationVariables
>
export const CreatePayrollAdjustmentDocument = gql`
  mutation CreatePayrollAdjustment($input: createPayrollAdjustmentInput!) {
    createPayrollAdjustment(input: $input) {
      payrollAdjustment {
        ...PayrollAdjustmentFragment
      }
    }
  }
  ${PayrollAdjustmentFragmentFragmentDoc}
`
export type CreatePayrollAdjustmentMutationFn = Apollo.MutationFunction<
  Types.CreatePayrollAdjustmentMutation,
  Types.CreatePayrollAdjustmentMutationVariables
>

/**
 * __useCreatePayrollAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreatePayrollAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollAdjustmentMutation, { data, loading, error }] = useCreatePayrollAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayrollAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePayrollAdjustmentMutation,
    Types.CreatePayrollAdjustmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreatePayrollAdjustmentMutation,
    Types.CreatePayrollAdjustmentMutationVariables
  >(CreatePayrollAdjustmentDocument, options)
}
export type CreatePayrollAdjustmentMutationHookResult = ReturnType<
  typeof useCreatePayrollAdjustmentMutation
>
export type CreatePayrollAdjustmentMutationResult =
  Apollo.MutationResult<Types.CreatePayrollAdjustmentMutation>
export type CreatePayrollAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePayrollAdjustmentMutation,
  Types.CreatePayrollAdjustmentMutationVariables
>
export const DeleteContractFilesDocument = gql`
  mutation DeleteContractFiles($input: deleteContractFilesInput!) {
    deleteContractFiles(input: $input) {
      result
    }
  }
`
export type DeleteContractFilesMutationFn = Apollo.MutationFunction<
  Types.DeleteContractFilesMutation,
  Types.DeleteContractFilesMutationVariables
>

/**
 * __useDeleteContractFilesMutation__
 *
 * To run a mutation, you first call `useDeleteContractFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractFilesMutation, { data, loading, error }] = useDeleteContractFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContractFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteContractFilesMutation,
    Types.DeleteContractFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteContractFilesMutation,
    Types.DeleteContractFilesMutationVariables
  >(DeleteContractFilesDocument, options)
}
export type DeleteContractFilesMutationHookResult = ReturnType<
  typeof useDeleteContractFilesMutation
>
export type DeleteContractFilesMutationResult =
  Apollo.MutationResult<Types.DeleteContractFilesMutation>
export type DeleteContractFilesMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteContractFilesMutation,
  Types.DeleteContractFilesMutationVariables
>
export const DeletePayrollAdjustmentDocument = gql`
  mutation DeletePayrollAdjustment($input: deletePayrollAdjustmentInput!) {
    deletePayrollAdjustment(input: $input) {
      result
    }
  }
`
export type DeletePayrollAdjustmentMutationFn = Apollo.MutationFunction<
  Types.DeletePayrollAdjustmentMutation,
  Types.DeletePayrollAdjustmentMutationVariables
>

/**
 * __useDeletePayrollAdjustmentMutation__
 *
 * To run a mutation, you first call `useDeletePayrollAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollAdjustmentMutation, { data, loading, error }] = useDeletePayrollAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayrollAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePayrollAdjustmentMutation,
    Types.DeletePayrollAdjustmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeletePayrollAdjustmentMutation,
    Types.DeletePayrollAdjustmentMutationVariables
  >(DeletePayrollAdjustmentDocument, options)
}
export type DeletePayrollAdjustmentMutationHookResult = ReturnType<
  typeof useDeletePayrollAdjustmentMutation
>
export type DeletePayrollAdjustmentMutationResult =
  Apollo.MutationResult<Types.DeletePayrollAdjustmentMutation>
export type DeletePayrollAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePayrollAdjustmentMutation,
  Types.DeletePayrollAdjustmentMutationVariables
>
export const EmployeeByFiltersDocument = gql`
  query EmployeeByFilters($filters: EmployeeByFiltersInput) {
    employeeByFilters(filters: $filters) {
      count
      employees {
        id
        fullNameOnId
        workEmail
        phoneNumber
        department
        address
        status
      }
    }
  }
`

/**
 * __useEmployeeByFiltersQuery__
 *
 * To run a query within a React component, call `useEmployeeByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEmployeeByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.EmployeeByFiltersQuery,
    Types.EmployeeByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.EmployeeByFiltersQuery,
    Types.EmployeeByFiltersQueryVariables
  >(EmployeeByFiltersDocument, options)
}
export function useEmployeeByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EmployeeByFiltersQuery,
    Types.EmployeeByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EmployeeByFiltersQuery,
    Types.EmployeeByFiltersQueryVariables
  >(EmployeeByFiltersDocument, options)
}
export type EmployeeByFiltersQueryHookResult = ReturnType<
  typeof useEmployeeByFiltersQuery
>
export type EmployeeByFiltersLazyQueryHookResult = ReturnType<
  typeof useEmployeeByFiltersLazyQuery
>
export type EmployeeByFiltersQueryResult = Apollo.QueryResult<
  Types.EmployeeByFiltersQuery,
  Types.EmployeeByFiltersQueryVariables
>
export const EmployeeByIdDocument = gql`
  query EmployeeById($id: ID!) {
    employeeById(id: $id) {
      ...EmployeeFragment
    }
  }
  ${EmployeeFragmentFragmentDoc}
`

/**
 * __useEmployeeByIdQuery__
 *
 * To run a query within a React component, call `useEmployeeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.EmployeeByIdQuery,
    Types.EmployeeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.EmployeeByIdQuery,
    Types.EmployeeByIdQueryVariables
  >(EmployeeByIdDocument, options)
}
export function useEmployeeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.EmployeeByIdQuery,
    Types.EmployeeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.EmployeeByIdQuery,
    Types.EmployeeByIdQueryVariables
  >(EmployeeByIdDocument, options)
}
export type EmployeeByIdQueryHookResult = ReturnType<
  typeof useEmployeeByIdQuery
>
export type EmployeeByIdLazyQueryHookResult = ReturnType<
  typeof useEmployeeByIdLazyQuery
>
export type EmployeeByIdQueryResult = Apollo.QueryResult<
  Types.EmployeeByIdQuery,
  Types.EmployeeByIdQueryVariables
>
export const ManagerByFiltersDocument = gql`
  query ManagerByFilters {
    allManagers {
      nodes {
        ... on SuperAdminUser {
          id
          type
          firstName
          lastName
        }
        ... on AdminUser {
          id
          type
          firstName
          lastName
        }
        ... on ManagerUser {
          id
          type
          firstName
          lastName
        }
        ... on StaffUser {
          id
          type
          firstName
          lastName
          employeeId
        }
      }
    }
  }
`

/**
 * __useManagerByFiltersQuery__
 *
 * To run a query within a React component, call `useManagerByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerByFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagerByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ManagerByFiltersQuery,
    Types.ManagerByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.ManagerByFiltersQuery,
    Types.ManagerByFiltersQueryVariables
  >(ManagerByFiltersDocument, options)
}
export function useManagerByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ManagerByFiltersQuery,
    Types.ManagerByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.ManagerByFiltersQuery,
    Types.ManagerByFiltersQueryVariables
  >(ManagerByFiltersDocument, options)
}
export type ManagerByFiltersQueryHookResult = ReturnType<
  typeof useManagerByFiltersQuery
>
export type ManagerByFiltersLazyQueryHookResult = ReturnType<
  typeof useManagerByFiltersLazyQuery
>
export type ManagerByFiltersQueryResult = Apollo.QueryResult<
  Types.ManagerByFiltersQuery,
  Types.ManagerByFiltersQueryVariables
>
export const MasterContractsByFiltersDocument = gql`
  query MasterContractsByFilters($filters: MasterContractsFilter) {
    masterContractsByFilters(filters: $filters) {
      count
      masterContracts {
        id
        annualLeave
        sickLeave
        hospitalLeave
        contractType
        country
        currency
        payrollPeriod
        publicHolidaySchedule
      }
    }
  }
`

/**
 * __useMasterContractsByFiltersQuery__
 *
 * To run a query within a React component, call `useMasterContractsByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterContractsByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterContractsByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMasterContractsByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MasterContractsByFiltersQuery,
    Types.MasterContractsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.MasterContractsByFiltersQuery,
    Types.MasterContractsByFiltersQueryVariables
  >(MasterContractsByFiltersDocument, options)
}
export function useMasterContractsByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MasterContractsByFiltersQuery,
    Types.MasterContractsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.MasterContractsByFiltersQuery,
    Types.MasterContractsByFiltersQueryVariables
  >(MasterContractsByFiltersDocument, options)
}
export type MasterContractsByFiltersQueryHookResult = ReturnType<
  typeof useMasterContractsByFiltersQuery
>
export type MasterContractsByFiltersLazyQueryHookResult = ReturnType<
  typeof useMasterContractsByFiltersLazyQuery
>
export type MasterContractsByFiltersQueryResult = Apollo.QueryResult<
  Types.MasterContractsByFiltersQuery,
  Types.MasterContractsByFiltersQueryVariables
>
export const PayrollAdjustmentByFiltersDocument = gql`
  query PayrollAdjustmentByFilters($filters: PayrollAdjustmentByFiltersInput) {
    payrollAdjustmentByFilters(filters: $filters) {
      count
      payrollAdjustments {
        ...PayrollAdjustmentFragment
      }
    }
  }
  ${PayrollAdjustmentFragmentFragmentDoc}
`

/**
 * __usePayrollAdjustmentByFiltersQuery__
 *
 * To run a query within a React component, call `usePayrollAdjustmentByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollAdjustmentByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollAdjustmentByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePayrollAdjustmentByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.PayrollAdjustmentByFiltersQuery,
    Types.PayrollAdjustmentByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.PayrollAdjustmentByFiltersQuery,
    Types.PayrollAdjustmentByFiltersQueryVariables
  >(PayrollAdjustmentByFiltersDocument, options)
}
export function usePayrollAdjustmentByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PayrollAdjustmentByFiltersQuery,
    Types.PayrollAdjustmentByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.PayrollAdjustmentByFiltersQuery,
    Types.PayrollAdjustmentByFiltersQueryVariables
  >(PayrollAdjustmentByFiltersDocument, options)
}
export type PayrollAdjustmentByFiltersQueryHookResult = ReturnType<
  typeof usePayrollAdjustmentByFiltersQuery
>
export type PayrollAdjustmentByFiltersLazyQueryHookResult = ReturnType<
  typeof usePayrollAdjustmentByFiltersLazyQuery
>
export type PayrollAdjustmentByFiltersQueryResult = Apollo.QueryResult<
  Types.PayrollAdjustmentByFiltersQuery,
  Types.PayrollAdjustmentByFiltersQueryVariables
>
export const TerminateEmployeeDocument = gql`
  mutation TerminateEmployee($input: terminateEmployeeInput!) {
    terminateEmployee(input: $input) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragmentFragmentDoc}
`
export type TerminateEmployeeMutationFn = Apollo.MutationFunction<
  Types.TerminateEmployeeMutation,
  Types.TerminateEmployeeMutationVariables
>

/**
 * __useTerminateEmployeeMutation__
 *
 * To run a mutation, you first call `useTerminateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateEmployeeMutation, { data, loading, error }] = useTerminateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.TerminateEmployeeMutation,
    Types.TerminateEmployeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.TerminateEmployeeMutation,
    Types.TerminateEmployeeMutationVariables
  >(TerminateEmployeeDocument, options)
}
export type TerminateEmployeeMutationHookResult = ReturnType<
  typeof useTerminateEmployeeMutation
>
export type TerminateEmployeeMutationResult =
  Apollo.MutationResult<Types.TerminateEmployeeMutation>
export type TerminateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  Types.TerminateEmployeeMutation,
  Types.TerminateEmployeeMutationVariables
>
export const UpdateContractDocument = gql`
  mutation UpdateContract($input: updateContractInput!) {
    updateContract(input: $input) {
      contract {
        ...ContractFragment
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`
export type UpdateContractMutationFn = Apollo.MutationFunction<
  Types.UpdateContractMutation,
  Types.UpdateContractMutationVariables
>

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateContractMutation,
    Types.UpdateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateContractMutation,
    Types.UpdateContractMutationVariables
  >(UpdateContractDocument, options)
}
export type UpdateContractMutationHookResult = ReturnType<
  typeof useUpdateContractMutation
>
export type UpdateContractMutationResult =
  Apollo.MutationResult<Types.UpdateContractMutation>
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateContractMutation,
  Types.UpdateContractMutationVariables
>
export const UpdateContractSalaryDocument = gql`
  mutation UpdateContractSalary($input: updateSalaryInput!) {
    updateSalary(input: $input) {
      contract {
        ...ContractFragment
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`
export type UpdateContractSalaryMutationFn = Apollo.MutationFunction<
  Types.UpdateContractSalaryMutation,
  Types.UpdateContractSalaryMutationVariables
>

/**
 * __useUpdateContractSalaryMutation__
 *
 * To run a mutation, you first call `useUpdateContractSalaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractSalaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractSalaryMutation, { data, loading, error }] = useUpdateContractSalaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractSalaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateContractSalaryMutation,
    Types.UpdateContractSalaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateContractSalaryMutation,
    Types.UpdateContractSalaryMutationVariables
  >(UpdateContractSalaryDocument, options)
}
export type UpdateContractSalaryMutationHookResult = ReturnType<
  typeof useUpdateContractSalaryMutation
>
export type UpdateContractSalaryMutationResult =
  Apollo.MutationResult<Types.UpdateContractSalaryMutation>
export type UpdateContractSalaryMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateContractSalaryMutation,
  Types.UpdateContractSalaryMutationVariables
>
export const UpdateEmployeeDocument = gql`
  mutation UpdateEmployee($input: updateEmployeeInput!) {
    updateEmployee(input: $input) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragmentFragmentDoc}
`
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<
  Types.UpdateEmployeeMutation,
  Types.UpdateEmployeeMutationVariables
>

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateEmployeeMutation,
    Types.UpdateEmployeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateEmployeeMutation,
    Types.UpdateEmployeeMutationVariables
  >(UpdateEmployeeDocument, options)
}
export type UpdateEmployeeMutationHookResult = ReturnType<
  typeof useUpdateEmployeeMutation
>
export type UpdateEmployeeMutationResult =
  Apollo.MutationResult<Types.UpdateEmployeeMutation>
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateEmployeeMutation,
  Types.UpdateEmployeeMutationVariables
>
export const UpdatePayrollAdjustmentDocument = gql`
  mutation UpdatePayrollAdjustment($input: updatePayrollAdjustmentInput!) {
    updatePayrollAdjustment(input: $input) {
      payrollAdjustment {
        ...PayrollAdjustmentFragment
      }
    }
  }
  ${PayrollAdjustmentFragmentFragmentDoc}
`
export type UpdatePayrollAdjustmentMutationFn = Apollo.MutationFunction<
  Types.UpdatePayrollAdjustmentMutation,
  Types.UpdatePayrollAdjustmentMutationVariables
>

/**
 * __useUpdatePayrollAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollAdjustmentMutation, { data, loading, error }] = useUpdatePayrollAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayrollAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePayrollAdjustmentMutation,
    Types.UpdatePayrollAdjustmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdatePayrollAdjustmentMutation,
    Types.UpdatePayrollAdjustmentMutationVariables
  >(UpdatePayrollAdjustmentDocument, options)
}
export type UpdatePayrollAdjustmentMutationHookResult = ReturnType<
  typeof useUpdatePayrollAdjustmentMutation
>
export type UpdatePayrollAdjustmentMutationResult =
  Apollo.MutationResult<Types.UpdatePayrollAdjustmentMutation>
export type UpdatePayrollAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePayrollAdjustmentMutation,
  Types.UpdatePayrollAdjustmentMutationVariables
>
export const UpdateProbationStatusDocument = gql`
  mutation UpdateProbationStatus($input: updateProbationStatusInput!) {
    updateProbationStatus(input: $input) {
      contract {
        ...ContractFragment
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`
export type UpdateProbationStatusMutationFn = Apollo.MutationFunction<
  Types.UpdateProbationStatusMutation,
  Types.UpdateProbationStatusMutationVariables
>

/**
 * __useUpdateProbationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProbationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProbationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProbationStatusMutation, { data, loading, error }] = useUpdateProbationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProbationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateProbationStatusMutation,
    Types.UpdateProbationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateProbationStatusMutation,
    Types.UpdateProbationStatusMutationVariables
  >(UpdateProbationStatusDocument, options)
}
export type UpdateProbationStatusMutationHookResult = ReturnType<
  typeof useUpdateProbationStatusMutation
>
export type UpdateProbationStatusMutationResult =
  Apollo.MutationResult<Types.UpdateProbationStatusMutation>
export type UpdateProbationStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProbationStatusMutation,
  Types.UpdateProbationStatusMutationVariables
>
export const UploadContractFilesDocument = gql`
  mutation UploadContractFiles($input: uploadContractFilesInput!) {
    uploadContractFiles(input: $input) {
      uploadedFiles {
        id
        filename
        downloadUrl
        createdAt
      }
    }
  }
`
export type UploadContractFilesMutationFn = Apollo.MutationFunction<
  Types.UploadContractFilesMutation,
  Types.UploadContractFilesMutationVariables
>

/**
 * __useUploadContractFilesMutation__
 *
 * To run a mutation, you first call `useUploadContractFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContractFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContractFilesMutation, { data, loading, error }] = useUploadContractFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadContractFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadContractFilesMutation,
    Types.UploadContractFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UploadContractFilesMutation,
    Types.UploadContractFilesMutationVariables
  >(UploadContractFilesDocument, options)
}
export type UploadContractFilesMutationHookResult = ReturnType<
  typeof useUploadContractFilesMutation
>
export type UploadContractFilesMutationResult =
  Apollo.MutationResult<Types.UploadContractFilesMutation>
export type UploadContractFilesMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadContractFilesMutation,
  Types.UploadContractFilesMutationVariables
>
export const CompletedOffboardingsDocument = gql`
  query CompletedOffboardings($filters: OffboardingFilterInput) {
    completedOffboardings(filters: $filters) {
      count
      employees {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragmentFragmentDoc}
`

/**
 * __useCompletedOffboardingsQuery__
 *
 * To run a query within a React component, call `useCompletedOffboardingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedOffboardingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedOffboardingsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompletedOffboardingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CompletedOffboardingsQuery,
    Types.CompletedOffboardingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.CompletedOffboardingsQuery,
    Types.CompletedOffboardingsQueryVariables
  >(CompletedOffboardingsDocument, options)
}
export function useCompletedOffboardingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompletedOffboardingsQuery,
    Types.CompletedOffboardingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.CompletedOffboardingsQuery,
    Types.CompletedOffboardingsQueryVariables
  >(CompletedOffboardingsDocument, options)
}
export type CompletedOffboardingsQueryHookResult = ReturnType<
  typeof useCompletedOffboardingsQuery
>
export type CompletedOffboardingsLazyQueryHookResult = ReturnType<
  typeof useCompletedOffboardingsLazyQuery
>
export type CompletedOffboardingsQueryResult = Apollo.QueryResult<
  Types.CompletedOffboardingsQuery,
  Types.CompletedOffboardingsQueryVariables
>
export const UncompletedOffboardingsDocument = gql`
  query UncompletedOffboardings($filters: OffboardingFilterInput) {
    uncompletedOffboardings(filters: $filters) {
      count
      employees {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragmentFragmentDoc}
`

/**
 * __useUncompletedOffboardingsQuery__
 *
 * To run a query within a React component, call `useUncompletedOffboardingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUncompletedOffboardingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUncompletedOffboardingsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUncompletedOffboardingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UncompletedOffboardingsQuery,
    Types.UncompletedOffboardingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.UncompletedOffboardingsQuery,
    Types.UncompletedOffboardingsQueryVariables
  >(UncompletedOffboardingsDocument, options)
}
export function useUncompletedOffboardingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UncompletedOffboardingsQuery,
    Types.UncompletedOffboardingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.UncompletedOffboardingsQuery,
    Types.UncompletedOffboardingsQueryVariables
  >(UncompletedOffboardingsDocument, options)
}
export type UncompletedOffboardingsQueryHookResult = ReturnType<
  typeof useUncompletedOffboardingsQuery
>
export type UncompletedOffboardingsLazyQueryHookResult = ReturnType<
  typeof useUncompletedOffboardingsLazyQuery
>
export type UncompletedOffboardingsQueryResult = Apollo.QueryResult<
  Types.UncompletedOffboardingsQuery,
  Types.UncompletedOffboardingsQueryVariables
>
export const ApproveLeaveApplicationDocument = gql`
  mutation ApproveLeaveApplication($input: approveLeaveApplicationInput!) {
    approveLeaveApplication(input: $input) {
      leaveApplication {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`
export type ApproveLeaveApplicationMutationFn = Apollo.MutationFunction<
  Types.ApproveLeaveApplicationMutation,
  Types.ApproveLeaveApplicationMutationVariables
>

/**
 * __useApproveLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useApproveLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveLeaveApplicationMutation, { data, loading, error }] = useApproveLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveLeaveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApproveLeaveApplicationMutation,
    Types.ApproveLeaveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ApproveLeaveApplicationMutation,
    Types.ApproveLeaveApplicationMutationVariables
  >(ApproveLeaveApplicationDocument, options)
}
export type ApproveLeaveApplicationMutationHookResult = ReturnType<
  typeof useApproveLeaveApplicationMutation
>
export type ApproveLeaveApplicationMutationResult =
  Apollo.MutationResult<Types.ApproveLeaveApplicationMutation>
export type ApproveLeaveApplicationMutationOptions = Apollo.BaseMutationOptions<
  Types.ApproveLeaveApplicationMutation,
  Types.ApproveLeaveApplicationMutationVariables
>
export const CreateLeaveApplicationDocument = gql`
  mutation CreateLeaveApplication($input: createLeaveApplicationInput!) {
    createLeaveApplication(input: $input) {
      leaveApplication {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`
export type CreateLeaveApplicationMutationFn = Apollo.MutationFunction<
  Types.CreateLeaveApplicationMutation,
  Types.CreateLeaveApplicationMutationVariables
>

/**
 * __useCreateLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useCreateLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaveApplicationMutation, { data, loading, error }] = useCreateLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeaveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateLeaveApplicationMutation,
    Types.CreateLeaveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.CreateLeaveApplicationMutation,
    Types.CreateLeaveApplicationMutationVariables
  >(CreateLeaveApplicationDocument, options)
}
export type CreateLeaveApplicationMutationHookResult = ReturnType<
  typeof useCreateLeaveApplicationMutation
>
export type CreateLeaveApplicationMutationResult =
  Apollo.MutationResult<Types.CreateLeaveApplicationMutation>
export type CreateLeaveApplicationMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateLeaveApplicationMutation,
  Types.CreateLeaveApplicationMutationVariables
>
export const DeleteLeaveApplicationDocument = gql`
  mutation DeleteLeaveApplication($input: deleteLeaveApplicationInput!) {
    deleteLeaveApplication(input: $input) {
      result
    }
  }
`
export type DeleteLeaveApplicationMutationFn = Apollo.MutationFunction<
  Types.DeleteLeaveApplicationMutation,
  Types.DeleteLeaveApplicationMutationVariables
>

/**
 * __useDeleteLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveApplicationMutation, { data, loading, error }] = useDeleteLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLeaveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteLeaveApplicationMutation,
    Types.DeleteLeaveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteLeaveApplicationMutation,
    Types.DeleteLeaveApplicationMutationVariables
  >(DeleteLeaveApplicationDocument, options)
}
export type DeleteLeaveApplicationMutationHookResult = ReturnType<
  typeof useDeleteLeaveApplicationMutation
>
export type DeleteLeaveApplicationMutationResult =
  Apollo.MutationResult<Types.DeleteLeaveApplicationMutation>
export type DeleteLeaveApplicationMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteLeaveApplicationMutation,
  Types.DeleteLeaveApplicationMutationVariables
>
export const DeleteLeaveApplicationFilesDocument = gql`
  mutation DeleteLeaveApplicationFiles(
    $input: deleteLeaveApplicationFilesInput!
  ) {
    deleteLeaveApplicationFiles(input: $input) {
      result
    }
  }
`
export type DeleteLeaveApplicationFilesMutationFn = Apollo.MutationFunction<
  Types.DeleteLeaveApplicationFilesMutation,
  Types.DeleteLeaveApplicationFilesMutationVariables
>

/**
 * __useDeleteLeaveApplicationFilesMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveApplicationFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveApplicationFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveApplicationFilesMutation, { data, loading, error }] = useDeleteLeaveApplicationFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLeaveApplicationFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteLeaveApplicationFilesMutation,
    Types.DeleteLeaveApplicationFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.DeleteLeaveApplicationFilesMutation,
    Types.DeleteLeaveApplicationFilesMutationVariables
  >(DeleteLeaveApplicationFilesDocument, options)
}
export type DeleteLeaveApplicationFilesMutationHookResult = ReturnType<
  typeof useDeleteLeaveApplicationFilesMutation
>
export type DeleteLeaveApplicationFilesMutationResult =
  Apollo.MutationResult<Types.DeleteLeaveApplicationFilesMutation>
export type DeleteLeaveApplicationFilesMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteLeaveApplicationFilesMutation,
    Types.DeleteLeaveApplicationFilesMutationVariables
  >
export const LeaveApplicationByFiltersDocument = gql`
  query LeaveApplicationByFilters($filters: LeaveApplicationByFiltersInput) {
    leaveApplicationByFilters(filters: $filters) {
      count
      leaveApplications {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`

/**
 * __useLeaveApplicationByFiltersQuery__
 *
 * To run a query within a React component, call `useLeaveApplicationByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveApplicationByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveApplicationByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useLeaveApplicationByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LeaveApplicationByFiltersQuery,
    Types.LeaveApplicationByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.LeaveApplicationByFiltersQuery,
    Types.LeaveApplicationByFiltersQueryVariables
  >(LeaveApplicationByFiltersDocument, options)
}
export function useLeaveApplicationByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LeaveApplicationByFiltersQuery,
    Types.LeaveApplicationByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.LeaveApplicationByFiltersQuery,
    Types.LeaveApplicationByFiltersQueryVariables
  >(LeaveApplicationByFiltersDocument, options)
}
export type LeaveApplicationByFiltersQueryHookResult = ReturnType<
  typeof useLeaveApplicationByFiltersQuery
>
export type LeaveApplicationByFiltersLazyQueryHookResult = ReturnType<
  typeof useLeaveApplicationByFiltersLazyQuery
>
export type LeaveApplicationByFiltersQueryResult = Apollo.QueryResult<
  Types.LeaveApplicationByFiltersQuery,
  Types.LeaveApplicationByFiltersQueryVariables
>
export const RejectLeaveApplicationDocument = gql`
  mutation RejectLeaveApplication($input: rejectLeaveApplicationInput!) {
    rejectLeaveApplication(input: $input) {
      leaveApplication {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`
export type RejectLeaveApplicationMutationFn = Apollo.MutationFunction<
  Types.RejectLeaveApplicationMutation,
  Types.RejectLeaveApplicationMutationVariables
>

/**
 * __useRejectLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useRejectLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectLeaveApplicationMutation, { data, loading, error }] = useRejectLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectLeaveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RejectLeaveApplicationMutation,
    Types.RejectLeaveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.RejectLeaveApplicationMutation,
    Types.RejectLeaveApplicationMutationVariables
  >(RejectLeaveApplicationDocument, options)
}
export type RejectLeaveApplicationMutationHookResult = ReturnType<
  typeof useRejectLeaveApplicationMutation
>
export type RejectLeaveApplicationMutationResult =
  Apollo.MutationResult<Types.RejectLeaveApplicationMutation>
export type RejectLeaveApplicationMutationOptions = Apollo.BaseMutationOptions<
  Types.RejectLeaveApplicationMutation,
  Types.RejectLeaveApplicationMutationVariables
>
export const UpdateLeaveApplicationDocument = gql`
  mutation UpdateLeaveApplication($input: updateLeaveApplicationInput!) {
    updateLeaveApplication(input: $input) {
      leaveApplication {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`
export type UpdateLeaveApplicationMutationFn = Apollo.MutationFunction<
  Types.UpdateLeaveApplicationMutation,
  Types.UpdateLeaveApplicationMutationVariables
>

/**
 * __useUpdateLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaveApplicationMutation, { data, loading, error }] = useUpdateLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeaveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateLeaveApplicationMutation,
    Types.UpdateLeaveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateLeaveApplicationMutation,
    Types.UpdateLeaveApplicationMutationVariables
  >(UpdateLeaveApplicationDocument, options)
}
export type UpdateLeaveApplicationMutationHookResult = ReturnType<
  typeof useUpdateLeaveApplicationMutation
>
export type UpdateLeaveApplicationMutationResult =
  Apollo.MutationResult<Types.UpdateLeaveApplicationMutation>
export type UpdateLeaveApplicationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateLeaveApplicationMutation,
  Types.UpdateLeaveApplicationMutationVariables
>
export const WithdrawLeaveRequestDocument = gql`
  mutation WithdrawLeaveRequest($input: withdrawLeaveRequestInput!) {
    withdrawLeaveRequest(input: $input) {
      leaveApplication {
        ...LeaveApplicationFragment
      }
    }
  }
  ${LeaveApplicationFragmentFragmentDoc}
`
export type WithdrawLeaveRequestMutationFn = Apollo.MutationFunction<
  Types.WithdrawLeaveRequestMutation,
  Types.WithdrawLeaveRequestMutationVariables
>

/**
 * __useWithdrawLeaveRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawLeaveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawLeaveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawLeaveRequestMutation, { data, loading, error }] = useWithdrawLeaveRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawLeaveRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WithdrawLeaveRequestMutation,
    Types.WithdrawLeaveRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.WithdrawLeaveRequestMutation,
    Types.WithdrawLeaveRequestMutationVariables
  >(WithdrawLeaveRequestDocument, options)
}
export type WithdrawLeaveRequestMutationHookResult = ReturnType<
  typeof useWithdrawLeaveRequestMutation
>
export type WithdrawLeaveRequestMutationResult =
  Apollo.MutationResult<Types.WithdrawLeaveRequestMutation>
export type WithdrawLeaveRequestMutationOptions = Apollo.BaseMutationOptions<
  Types.WithdrawLeaveRequestMutation,
  Types.WithdrawLeaveRequestMutationVariables
>
export const ApplyPaymentDocument = gql`
  mutation ApplyPayment($input: applyPaymentInput!) {
    applyPayment(input: $input) {
      payroll {
        ...PayrollFragment
      }
    }
  }
  ${PayrollFragmentFragmentDoc}
`
export type ApplyPaymentMutationFn = Apollo.MutationFunction<
  Types.ApplyPaymentMutation,
  Types.ApplyPaymentMutationVariables
>

/**
 * __useApplyPaymentMutation__
 *
 * To run a mutation, you first call `useApplyPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPaymentMutation, { data, loading, error }] = useApplyPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApplyPaymentMutation,
    Types.ApplyPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ApplyPaymentMutation,
    Types.ApplyPaymentMutationVariables
  >(ApplyPaymentDocument, options)
}
export type ApplyPaymentMutationHookResult = ReturnType<
  typeof useApplyPaymentMutation
>
export type ApplyPaymentMutationResult =
  Apollo.MutationResult<Types.ApplyPaymentMutation>
export type ApplyPaymentMutationOptions = Apollo.BaseMutationOptions<
  Types.ApplyPaymentMutation,
  Types.ApplyPaymentMutationVariables
>
export const ApplyPaymentsCsvDocument = gql`
  mutation ApplyPaymentsCsv($input: applyPaymentCsvInput!) {
    applyPaymentCsv(input: $input) {
      result
      errors {
        error
        row
        rowIndex
      }
    }
  }
`
export type ApplyPaymentsCsvMutationFn = Apollo.MutationFunction<
  Types.ApplyPaymentsCsvMutation,
  Types.ApplyPaymentsCsvMutationVariables
>

/**
 * __useApplyPaymentsCsvMutation__
 *
 * To run a mutation, you first call `useApplyPaymentsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPaymentsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPaymentsCsvMutation, { data, loading, error }] = useApplyPaymentsCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyPaymentsCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ApplyPaymentsCsvMutation,
    Types.ApplyPaymentsCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ApplyPaymentsCsvMutation,
    Types.ApplyPaymentsCsvMutationVariables
  >(ApplyPaymentsCsvDocument, options)
}
export type ApplyPaymentsCsvMutationHookResult = ReturnType<
  typeof useApplyPaymentsCsvMutation
>
export type ApplyPaymentsCsvMutationResult =
  Apollo.MutationResult<Types.ApplyPaymentsCsvMutation>
export type ApplyPaymentsCsvMutationOptions = Apollo.BaseMutationOptions<
  Types.ApplyPaymentsCsvMutation,
  Types.ApplyPaymentsCsvMutationVariables
>
export const ConfirmPayrollDocument = gql`
  mutation ConfirmPayroll($input: confirmPayrollInput!) {
    confirmPayroll(input: $input) {
      payroll {
        ...PayrollFragment
      }
    }
  }
  ${PayrollFragmentFragmentDoc}
`
export type ConfirmPayrollMutationFn = Apollo.MutationFunction<
  Types.ConfirmPayrollMutation,
  Types.ConfirmPayrollMutationVariables
>

/**
 * __useConfirmPayrollMutation__
 *
 * To run a mutation, you first call `useConfirmPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPayrollMutation, { data, loading, error }] = useConfirmPayrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ConfirmPayrollMutation,
    Types.ConfirmPayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ConfirmPayrollMutation,
    Types.ConfirmPayrollMutationVariables
  >(ConfirmPayrollDocument, options)
}
export type ConfirmPayrollMutationHookResult = ReturnType<
  typeof useConfirmPayrollMutation
>
export type ConfirmPayrollMutationResult =
  Apollo.MutationResult<Types.ConfirmPayrollMutation>
export type ConfirmPayrollMutationOptions = Apollo.BaseMutationOptions<
  Types.ConfirmPayrollMutation,
  Types.ConfirmPayrollMutationVariables
>
export const ConfirmPayrollBatchDocument = gql`
  mutation ConfirmPayrollBatch($input: confirmPayrollBatchInput!) {
    confirmPayrollBatch(input: $input) {
      payrolls {
        ...PayrollFragment
      }
    }
  }
  ${PayrollFragmentFragmentDoc}
`
export type ConfirmPayrollBatchMutationFn = Apollo.MutationFunction<
  Types.ConfirmPayrollBatchMutation,
  Types.ConfirmPayrollBatchMutationVariables
>

/**
 * __useConfirmPayrollBatchMutation__
 *
 * To run a mutation, you first call `useConfirmPayrollBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPayrollBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPayrollBatchMutation, { data, loading, error }] = useConfirmPayrollBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPayrollBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ConfirmPayrollBatchMutation,
    Types.ConfirmPayrollBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.ConfirmPayrollBatchMutation,
    Types.ConfirmPayrollBatchMutationVariables
  >(ConfirmPayrollBatchDocument, options)
}
export type ConfirmPayrollBatchMutationHookResult = ReturnType<
  typeof useConfirmPayrollBatchMutation
>
export type ConfirmPayrollBatchMutationResult =
  Apollo.MutationResult<Types.ConfirmPayrollBatchMutation>
export type ConfirmPayrollBatchMutationOptions = Apollo.BaseMutationOptions<
  Types.ConfirmPayrollBatchMutation,
  Types.ConfirmPayrollBatchMutationVariables
>
export const GeneratePayrollCsvDocument = gql`
  mutation GeneratePayrollCsv($input: generatePayrollsCsvInput!) {
    generatePayrollsCsv(input: $input) {
      fileString
    }
  }
`
export type GeneratePayrollCsvMutationFn = Apollo.MutationFunction<
  Types.GeneratePayrollCsvMutation,
  Types.GeneratePayrollCsvMutationVariables
>

/**
 * __useGeneratePayrollCsvMutation__
 *
 * To run a mutation, you first call `useGeneratePayrollCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePayrollCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePayrollCsvMutation, { data, loading, error }] = useGeneratePayrollCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePayrollCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GeneratePayrollCsvMutation,
    Types.GeneratePayrollCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.GeneratePayrollCsvMutation,
    Types.GeneratePayrollCsvMutationVariables
  >(GeneratePayrollCsvDocument, options)
}
export type GeneratePayrollCsvMutationHookResult = ReturnType<
  typeof useGeneratePayrollCsvMutation
>
export type GeneratePayrollCsvMutationResult =
  Apollo.MutationResult<Types.GeneratePayrollCsvMutation>
export type GeneratePayrollCsvMutationOptions = Apollo.BaseMutationOptions<
  Types.GeneratePayrollCsvMutation,
  Types.GeneratePayrollCsvMutationVariables
>
export const PayrollsByFiltersDocument = gql`
  query PayrollsBYFilters($filters: PayrollByFiltersInput) {
    payrollByFilters(filters: $filters) {
      count
      payrolls {
        ...PayrollFragment
      }
    }
  }
  ${PayrollFragmentFragmentDoc}
`

/**
 * __usePayrollsByFiltersQuery__
 *
 * To run a query within a React component, call `usePayrollsByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollsByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollsByFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePayrollsByFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.PayrollsByFiltersQuery,
    Types.PayrollsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.PayrollsByFiltersQuery,
    Types.PayrollsByFiltersQueryVariables
  >(PayrollsByFiltersDocument, options)
}
export function usePayrollsByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PayrollsByFiltersQuery,
    Types.PayrollsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.PayrollsByFiltersQuery,
    Types.PayrollsByFiltersQueryVariables
  >(PayrollsByFiltersDocument, options)
}
export type PayrollsByFiltersQueryHookResult = ReturnType<
  typeof usePayrollsByFiltersQuery
>
export type PayrollsByFiltersLazyQueryHookResult = ReturnType<
  typeof usePayrollsByFiltersLazyQuery
>
export type PayrollsByFiltersQueryResult = Apollo.QueryResult<
  Types.PayrollsByFiltersQuery,
  Types.PayrollsByFiltersQueryVariables
>
export const PayrollByIdDocument = gql`
  query PayrollById($id: ID!) {
    payrollById(id: $id) {
      ...PayrollFragment
    }
  }
  ${PayrollFragmentFragmentDoc}
`

/**
 * __usePayrollByIdQuery__
 *
 * To run a query within a React component, call `usePayrollByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayrollByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.PayrollByIdQuery,
    Types.PayrollByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Types.PayrollByIdQuery,
    Types.PayrollByIdQueryVariables
  >(PayrollByIdDocument, options)
}
export function usePayrollByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PayrollByIdQuery,
    Types.PayrollByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.PayrollByIdQuery,
    Types.PayrollByIdQueryVariables
  >(PayrollByIdDocument, options)
}
export type PayrollByIdQueryHookResult = ReturnType<typeof usePayrollByIdQuery>
export type PayrollByIdLazyQueryHookResult = ReturnType<
  typeof usePayrollByIdLazyQuery
>
export type PayrollByIdQueryResult = Apollo.QueryResult<
  Types.PayrollByIdQuery,
  Types.PayrollByIdQueryVariables
>
export const RegeneratePayrollDocument = gql`
  mutation RegeneratePayroll($input: regeneratePayrollInput!) {
    regeneratePayroll(input: $input) {
      payroll {
        id
      }
    }
  }
`
export type RegeneratePayrollMutationFn = Apollo.MutationFunction<
  Types.RegeneratePayrollMutation,
  Types.RegeneratePayrollMutationVariables
>

/**
 * __useRegeneratePayrollMutation__
 *
 * To run a mutation, you first call `useRegeneratePayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegeneratePayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regeneratePayrollMutation, { data, loading, error }] = useRegeneratePayrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegeneratePayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RegeneratePayrollMutation,
    Types.RegeneratePayrollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.RegeneratePayrollMutation,
    Types.RegeneratePayrollMutationVariables
  >(RegeneratePayrollDocument, options)
}
export type RegeneratePayrollMutationHookResult = ReturnType<
  typeof useRegeneratePayrollMutation
>
export type RegeneratePayrollMutationResult =
  Apollo.MutationResult<Types.RegeneratePayrollMutation>
export type RegeneratePayrollMutationOptions = Apollo.BaseMutationOptions<
  Types.RegeneratePayrollMutation,
  Types.RegeneratePayrollMutationVariables
>
export const SignOutDocument = gql`
  mutation signOut {
    signOut(input: {}) {
      user {
        ... on AdminUser {
          id
        }
      }
    }
  }
`
export type SignOutMutationFn = Apollo.MutationFunction<
  Types.SignOutMutation,
  Types.SignOutMutationVariables
>

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SignOutMutation,
    Types.SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.SignOutMutation,
    Types.SignOutMutationVariables
  >(SignOutDocument, options)
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>
export type SignOutMutationResult = Apollo.MutationResult<Types.SignOutMutation>
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  Types.SignOutMutation,
  Types.SignOutMutationVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    SendResetPasswordResult: ['NotFoundProblem'],
    User: ['AdminUser', 'ManagerUser', 'StaffUser', 'SuperAdminUser'],
    signInResult: ['SignInOk', 'ValidationProblem']
  }
}
export default result
